<template>
  <div id="show-employee-container">
    <div id="main-container">
      <Nav navOptionChosen="trips"></Nav>
      <section class="main-section">
        <TitleSection title="Detalles de viaje" :loading="loading">
        </TitleSection>
        <MadeBy
          :created_by="trip.created_by_name"
          :created_at="trip.created_at"
        ></MadeBy>
        <div id="info-container">
          <section class="info-section">
            <div class="info-group">
              <h4 class="subtitle">Nombre</h4>
              <p class="p-info">{{ trip.name }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Espacios disponibles</h4>
              <p class="p-info">{{ trip.free_spots }} de {{ trip.spots }}</p>
              <div class="spots-container">
                <div
                  class="spot-number center"
                  :class="[spot.is_available ? 'free-spot' : 'busy-spot']"
                  v-for="spot in spots"
                  :key="spot.id"
                >
                  {{ spot.spot_number }}
                </div>
              </div>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Descripción</h4>
              <p class="p-info">{{ trip.description }}</p>
            </div>
          </section>
          <section class="info-section">
            <div class="info-group">
              <h4 class="subtitle">Fecha de partida</h4>
              <p class="p-info">
                {{
                  trip.departure_date
                    ? trip.departure_date.substring(0, 16)
                    : ""
                }}
              </p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Fecha de llegada</h4>
              <p class="p-info">
                {{
                  trip.arrival_date ? trip.arrival_date.substring(0, 16) : ""
                }}
              </p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Lugar de partida</h4>
              <p class="p-info">{{ trip.departure_place }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Lugar de llegada</h4>
              <p class="p-info">{{ trip.arrival_place }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Descargables</h4>
              <a class="p-info download-link" @click.prevent="donwloadCSV"
                >CSV de reservaciones</a
              >
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import MadeBy from "../../components/MadeBy.vue";

// Services
import { show, index_spots, reservations_report } from "@/services/trips";

export default {
  name: "TripView",
  data: function () {
    return {
      loading: [],
      trip: {},
      spots: [],
      reservationsReportDisabled: false,
    };
  },
  created() {
    this.loadTrip();
    this.loadSpots();
  },
  methods: {
    loadTrip: async function () {
      try {
        let response = await show(this.$route.params.id);
        this.trip = response.data.data;
      } catch (err) {
        this.$swal.fire({
          title: "No pudimos cargar la información",
          text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    loadSpots: async function () {
      try {
        let response = await index_spots(this.$route.params.id);
        this.spots = response.data.data;
      } catch (err) {
        this.$swal.fire({
          title: "No pudimos cargar la información",
          text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    donwloadCSV: async function () {
      if (this.reservationsReportDisabled === false) {
        try {
          this.reservationsReportDisabled = true;
          this.loading.push(true);
          let response = await reservations_report(this.$route.params.id);
          const blob = new Blob([response.data], { type: "text/csv" });
          const fileURL = URL.createObjectURL(blob);
          window.location.href = fileURL;
        } catch (err) {
          this.$swal.fire({
            title: "No pudimos descargar el archivo",
            text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        } finally {
          this.reservationsReportDisabled = false;
          this.loading.pop();
        }
      }
    },
  },
  components: {
    Nav,
    TitleSection,
    MadeBy,
  },
};
</script>

<style lang="scss" scoped>
#show-employee-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

#info-container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 2em;
  color: var(--light);
}

.info-section {
  width: 50%;
}

.info-group {
  width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.subtitle {
  font-size: 0.7em;
  font-weight: bold;
  margin-bottom: 0.7em;
}

.p-info {
  font-size: 0.9em;
}
</style>
